import React from "react";
import Layout from "~components/base/layout";
import SEO from "~components/base/seo";
import Products from "~components/products/products";
import "../styles/pages/_lp.scss";
import lpIntro from "../images/lp/TD_1_hour_landing_page_hero.png";
import lpHelp from "../images/lp/TD_1_hour_landing_page.png";

const MarketingProblemsSolved = () => (
  <Layout type="template-lp">
    <SEO
      title="Marketing Problems. Solved."
      description="Got marketing problems? A 1-hour consult with a Fractional CMO can help solve them – at a fraction of the price. Book your consultation today."
    />

    <section className="cmo-lp-section-intro">
      <div className="container wrapper">
        <h1>What's Your Problem?</h1>
        <img src={lpIntro} alt="" />
      </div>
    </section>
    <section className="cmo-lp-section2">
      <div className="container wrapper">
        <h2>No, actually, what is the problem?</h2>
        <p>
          Usually, the worst problems are those you can’t even fully identify
          yourself. If you’re having issues with your website, brand, or general
          marketing, but can’t quite put your finger on what they are or how to
          get past them, you need to call in the experts to diagnose those
          marketing pain points.
        </p>
      </div>
    </section>
    <section className="cmo-lp-section-help">
      <div className="container wrapper">
        <div>
          <h2>We can help.</h2>
          <p>
            With The Simple Department’s 1 Hour Consulting Package, we’ll do a
            deep dive to discover what’s behind any marketing pain points you’re
            experiencing and find ways for you to move forward. Think of it as a
            counseling session, but for your marketing pain instead of your
            existential dread.
          </p>
        </div>
        <img src={lpHelp} alt="" />
      </div>
    </section>
    <section className="cmo-lp-section-typeform">
      <div className="container wrapper">
        <h2>Tell us about your problems</h2>
        <div className="typeform-button">
          <span className="btn btn-dark">CHEAPER THAN A THERAPIST</span>
        </div>
        <p className="sub-text mt-5">
          <i>
            This will launch a chat window to speak directly to one of our
            marketing experts.
          </i>
        </p>
      </div>
    </section>
    <section className="cmo-lp-section-product">
      <div className="container wrapper">
        <h2 className="text-center">What are you waiting for?</h2>
        <p className="text-center color-blue">Book your consultation now.</p>
        <Products type="slim">
          <div
            title="Dissecting Your Data | Marketing Consulting | 1 Hour"
            id="cmo-dissecting"
            price="$250"
            extrainfo="In this 1-hour session, we will break down your data and help you
            use it to take informed action."
          ></div>
          <div
            title="Avoid Stupid Mistakes | Marketing Consulting | 1 Hour"
            id="cmo-mistakes"
            price="$250"
            extrainfo="Perspective, objectivity and experience. That is what we bring to
            the conversation. Choose this option if you’re launching a new
            product, brand, service or market."
          ></div>
          <div
            title="Keeping It Simple, since ’83 | Hourly Marketing Consulting"
            id="cmo-hr"
            price="$250/hr"
            extrainfo=" At The Simple Department, we are used to helping companies like
            yours assess, troubleshoot, and move forward. These are open
            sessions that can help completely change your trajectory – think
            of it as therapy sessions for your business."
          ></div>
        </Products>
      </div>
    </section>
  </Layout>
);

export default MarketingProblemsSolved;
